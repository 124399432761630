var manageGTM = (function () {
    function hasAnalyticsEvent() {
        return typeof analyticsEvent !== typeof undefined;
    }

    function trackContactPageTelephoneClick(event) {
        return;
        if (hasAnalyticsEvent()) {
            $tipoContatto = $(event).closest('.item').find('h5').text();
            analyticsEvent('Contact page', 'Telephone - Click', $.trim($tipoContatto));
        }
    }

    function trackContactPageEmailClick(event) {
        if (hasAnalyticsEvent()) {
            $href = $(event).attr("href");
            $email = $.trim($href.replace("mailto:", ""));
            analyticsEvent('Contact page', 'Email - Click', $email);
        }
    }

    function trackContactPageFormOnlineBtnClick() {
        if (hasAnalyticsEvent()) {
            analyticsEvent('Contact page', 'Contact Form', 'Open');
        }

    }

    function trackContactPageFaqBtnClick(event) {
        if (hasAnalyticsEvent()) {
            $UserType = $(event).find("p").text();
            analyticsEvent('Contact page', 'FAQ', $.trim($UserType));
        }

    }

    function trackQuickLinksClick(event) {
        if (hasAnalyticsEvent()) {
            $ClickText = $.trim($(event).find("span").text());
            $ClickURL = $.trim($(event).attr("href"));
            analyticsEvent('Contact page', 'FloatingMenu', $ClickText + ' - ' + $ClickURL);
        }

    }

    function trackSliderOfferClick(event) {
        if (hasAnalyticsEvent()) {
            $Posizione = $.trim($(event).closest(".item").attr('data-position'));
            $NomeSlider = $.trim($(event).closest(".item").find(".offer-type img").attr("alt"));
            $Url = $.trim($(event).attr("href"));
            analyticsEvent('Slider Homepage', $Posizione + ' - ' + $NomeSlider, $Url);
        }


    }

    function trackSliderClick(event) {
        /**
         * CHECK IF URL ITALY AND IS HOMEPAGE
         */
        if (hasAnalyticsEvent()) {
            var getPath = window.location.pathname;
            $Posizione = $.trim($(event).closest(".item").attr('data-position'));
            $NomeSlider = $.trim($(event).attr("title"));
            if (typeof $NomeSlider === typeof undefined || $NomeSlider === '') {
                $NomeSlider = $.trim(event.innerText);
            }
            $Url = $.trim($(event).attr("href"));
            analyticsEvent('Slider Homepage', $Posizione + ' - ' + $NomeSlider, $Url);
        }


    }

    function trackLandingPageDetailOfferClick(event) {
        var titles = $(event).closest(".offer-box").find(".offer-box-front .offer-box-title");
        if (titles.length) {
            var brand = titles[0] ? $(titles[0]).text() : '';
            var model = titles[1] ? $(titles[1]).text() : '';
            var landingName = $(".convenzioni").val();
            trackLandingPageOfferClick("Landing page - " + landingName, 'Go to offer', brand + ' ' + model);
        }
    }

    function trackLandingPageOfferClick(category, action, label) {
        if (hasAnalyticsEvent()) {
            analyticsEvent(category, action, label);
        }

    }

    //track utility/navigation header and footer click
    function trackMenuClick(menuRegion, menuText, menuPath){
        window.dataLayer.push({
            'event': 'menu_click',
            'menu_ui_region': menuRegion,
            'menu_item': menuText,
            'menu_path': menuPath
        });
        //console.log(menuRegion + ' ' + menuText + ' ' + menuPath);
    }

    //track slider dots click
    function trackSliderDotsClick(index){
        window.dataLayer.push({
            'event': 'home_slider_arrow_click',
            'home_slider_index': index
        });
        //console.log(index);
    }

    //track slider cta click
    function trackSliderCtaClick(slideNumber, slideName, ctaPath){
        window.dataLayer.push({
            'event': 'home_slider_click',
            'eventCategory': 'Slider Homepage',
            'eventAction': slideNumber + '-' + slideName,
            'eventLabel': ctaPath
        });
        //console.log(slideNumber + '-' + slideName + ' ' + ctaPath);
    }

    //track offer card click
    function trackOfferCardClick(offerVehicle){
        window.dataLayer.push({
            'event': 'offer_click',
            'offer_vehicle': offerVehicle
        });
        //console.log(offerVehicle);
    }

    //track landing offer card click
    function trackLandingOfferClick(landingPage, offerVehicle){
        window.dataLayer.push({
            'event': 'analyticsEvent',
            'eventCategory': 'Landing page - ' + landingPage,
            'eventAction': 'Open offer Detail',
            'eventLabel': offerVehicle
        });
        //console.log("Landing page - " + landingPage + ' ' + offerVehicle);
    }

    //track ask for a quote click
    function trackAskQuoteClick(offerVehicle){
        window.dataLayer.push({
            'event': 'ask_quote_click',
            'offer_vehicle': offerVehicle
        });
        //console.log("Quote click - " + offerVehicle);
    }

    //track contact page clicks
    function trackContactPageClick(clickType, clickDestination){
        window.dataLayer.push({
            'event': 'contact_page_click',
            'click_type': clickType,
            'click_destination': clickDestination
        });
        console.log(clickType + ' ' + clickDestination);
    }

    //track faq page clicks
    function trackFaqClick(clickType, faqType){
        /*window.dataLayer.push({
            'event': 'FAQ_click',
            'click_type': clickType,
            'FAQ_type': faqType
        });*/
        console.log(clickType + ' ' + faqType);
    }

    return {
        trackContactPageTelephoneClick: trackContactPageTelephoneClick,
        trackContactPageEmailClick: trackContactPageEmailClick,
        trackContactPageFormOnlineBtnClick: trackContactPageFormOnlineBtnClick,
        trackContactPageFaqBtnClick: trackContactPageFaqBtnClick,
        trackQuickLinksClick: trackQuickLinksClick,
        trackSliderOfferClick: trackSliderOfferClick,
        trackSliderClick: trackSliderClick,
        trackLandingPageOfferClick: trackLandingPageOfferClick,
        trackLandingPageDetailOfferClick: trackLandingPageDetailOfferClick,
        //new implementation with datalayer
        trackMenuClick: trackMenuClick,
        trackSliderDotsClick: trackSliderDotsClick,
        trackSliderCtaClick: trackSliderCtaClick,
        trackOfferCardClick: trackOfferCardClick,
        trackLandingOfferClick: trackLandingOfferClick,
        trackAskQuoteClick: trackAskQuoteClick,
        trackContactPageClick: trackContactPageClick,
        trackFaqClick: trackFaqClick
    };
})(jQuery);