var servicesRestyledManager = (function () {
    var pageHasLoaded = $(".section-car-service-list-restyled").length;
    var accordion;
    var detailList;
    var tmpAccordion;
    var tmpList;
    var accordionParentContainer;
    var listParentContainer;
    var isMobile;
    function init() {
        $(document).ready(function () {
            if (pageHasLoaded){
                initializeListOrAccordion();
                $(window).on("resize", function() {
                    contentAdaptationOnScreenWidth();
                });
            }
        });
    }

    function initializeListOrAccordion(){
        tmpAccordion = [];
        tmpList = [];
        accordionParentContainer = [];
        listParentContainer = [];
        accordion = $('.section-car-service-list-restyled .accordion-services-container .accordionMobile');
        detailList = $('.section-car-service-list-restyled .car-services-row .listDesktop');
        accordion.parent().each(function(){
            tmpAccordion.push($(this).html());
            accordionParentContainer.push($(this).parent());
        });
        detailList.parent().each(function(){
            tmpList.push($(this).html());
            listParentContainer.push($(this).parent());
        });

        if($(window).width() < 992){
            isMobile = true;
            detailList.remove();
        } else if($(window).width() >= 992){
            isMobile = false;
            $('.section-car-service-list-restyled .accordion-services-container .accordionMobile').remove();
        }
    }

    function contentAdaptationOnScreenWidth(){
        if($(window).width() < 992 && !isMobile){
            $('.section-car-service-list-restyled .car-services-row .listDesktop').remove();
            $('.section-car-service-list-restyled .accordion-services-container').each(function(index) {
                $(this).html(tmpAccordion[index]);
            });
            isMobile = true;
        } else if($(window).width() >= 992 && isMobile){
            $('.section-car-service-list-restyled .car-services-row').each(function(index){
                $(this).html(tmpList[index])});
            $('.section-car-service-list-restyled .accordion-services-container .accordionMobile').remove();
            isMobile = false;
        }
    }

    return {
        init: init
    };
})(jQuery);
