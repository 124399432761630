var manageGTM = (function () {
    function hasAnalyticsEvent() {
        return typeof analyticsEvent !== typeof undefined;
    }

    function trackContactPageTelephoneClick(event) {
        if (hasAnalyticsEvent()) {
            $tipoContatto = $(event).closest('.item').find('h5').text();
            analyticsEvent('Contact page', 'Telephone - Click', $.trim($tipoContatto));
        }

    }

    function trackContactPageEmailClick(event) {
        if (hasAnalyticsEvent()) {
            $href = $(event).attr("href");
            $email = $.trim($href.replace("mailto:", ""));
            analyticsEvent('Contact page', 'Email - Click', $email);
        }
    }

    function trackContactPageFormOnlineBtnClick() {
        if (hasAnalyticsEvent()) {
            analyticsEvent('Contact page', 'Contact Form', 'Open');
        }

    }

    function trackContactPageFaqBtnClick(event) {
        if (hasAnalyticsEvent()) {
            $UserType = $(event).find("p").text();
            analyticsEvent('Contact page', 'FAQ', $.trim($UserType));
        }

    }

    function trackQuickLinksClick(event) {
        if (hasAnalyticsEvent()) {
            $ClickText = $.trim($(event).find("span").text());
            $ClickURL = $.trim($(event).attr("href"));
            analyticsEvent('Contact page', 'FloatingMenu', $ClickText + ' - ' + $ClickURL);
        }

    }

    function trackSliderOfferClick(event) {
        if (hasAnalyticsEvent()) {
            $Posizione = $.trim($(event).closest(".item").attr('data-position'));
            $NomeSlider = $.trim($(event).closest(".item").find(".offer-type img").attr("alt"));
            $Url = $.trim($(event).attr("href"));
            analyticsEvent('Slider Homepage', $Posizione + ' - ' + $NomeSlider, $Url);
        }


    }

    function trackSliderClick(event) {
        /**
         * CHECK IF URL ITALY AND IS HOMEPAGE
         */
        if (hasAnalyticsEvent()) {
            var getPath = window.location.pathname;
            $Posizione = $.trim($(event).closest(".item").attr('data-position'));
            $NomeSlider = $.trim($(event).attr("title"));
            if (typeof $NomeSlider === typeof undefined || $NomeSlider === '') {
                $NomeSlider = $.trim(event.innerText);
            }
            $Url = $.trim($(event).attr("href"));
            analyticsEvent('Slider Homepage', $Posizione + ' - ' + $NomeSlider, $Url);
        }


    }

    function trackLandingPageDetailOfferClick(event) {
        var titles = $(event).closest(".offer-box").find(".offer-box-front .offer-box-title");
        if (titles.length) {
            var brand = titles[0] ? $(titles[0]).text() : '';
            var model = titles[1] ? $(titles[1]).text() : '';
            var landingName = $(".convenzioni").val();
            trackLandingPageOfferClick("Landing page - " + landingName, 'Go to offer', brand + ' ' + model);
        }
    }

    function trackLandingPageOfferClick(category, action, label) {
        if (hasAnalyticsEvent()) {
            analyticsEvent(category, action, label);
        }

    }

    return {
        trackContactPageTelephoneClick: trackContactPageTelephoneClick,
        trackContactPageEmailClick: trackContactPageEmailClick,
        trackContactPageFormOnlineBtnClick: trackContactPageFormOnlineBtnClick,
        trackContactPageFaqBtnClick: trackContactPageFaqBtnClick,
        trackQuickLinksClick: trackQuickLinksClick,
        trackSliderOfferClick: trackSliderOfferClick,
        trackSliderClick: trackSliderClick,
        trackLandingPageOfferClick: trackLandingPageOfferClick,
        trackLandingPageDetailOfferClick: trackLandingPageDetailOfferClick
    };
})(jQuery);