var claimRequestForm = (function () {
    function init() {
        var claimForm = $('.claim-request-form-authentication');

        $('#circumstanceType').select2({
            allowClear: true
        });

        $('#circumstanceInvolvedVehicles').select2({
            allowClear: true
        });

        var tamperingDanger = {};
        tamperingDanger.value = false;
        var storedFiles = [];

        $(document).ready( function() {
            $("#attachmentsButton").on("click", function () {
                $("#attachmentsFile").trigger("click");
            });

            $('input[type="file"]').on("change", function () {
                var numberOfAttachments = $('.new-attachment').length;
                let dt = new DataTransfer();
                $('.attachments-form').append("\
                <div class='d-flex'><input class='new-attachment' id='attachmentsField"+ numberOfAttachments +"' name='attachments' placeholder='"+$(this).val().split('\\').pop()+"' type='text' disabled>" +
                    "\<span id='attachmentsField"+ numberOfAttachments +"' class='cross_icon fas fa-times fa-lg' style='color: #c20e1a'></span></div>");
                storedFiles.push($(this).prop('files'));
                storedFiles.forEach((file) => { dt.items.add(file[0]); });
                document.getElementById('attachmentsFile').files = dt.files;
            });

            $(".attachments-form").on("click", "span.cross_icon", function () {
                let dt = new DataTransfer();
                storedFiles.splice($('.cross_icon').index($(this)), 1);
                storedFiles.forEach((file) => { dt.items.add(file[0]); });
                document.getElementById('attachmentsFile').files = dt.files;
                $(this).parent().remove();
            });
        });

        claimForm.each(function () {

            var currentForm = $(this);

            $(this).submit(function (e) {
                e.preventDefault();
                currentForm.find('.is-invalid').removeClass('is-invalid');
                var errors = validateForm(currentForm);
                if(errors.length === 0){
                    $.ajax({
                        url: $(this).attr('action'),
                        type: $(this).attr('method'),
                        dataType: 'json',
                        data: $(this).serialize(),
                        success: function (json) {
                            manageResponse(json, currentForm);
                        }
                    });
                }else{
                    showErrors(currentForm, errors, '');
                }
            });


        });

        window.onload = function() {
            setCaiListener();
            setCircumstanceOtherTextListener();
        }

    }

    function setCircumstanceOtherTextListener(){
        let selectedValue = document.getElementById('select2-circumstanceType-container');
        if(selectedValue !== undefined && selectedValue !== null){
            $('#circumstanceType').on('change', function (){
                if($('#circumstanceType').val() === "altro"){
                    $('#circumstanceTextContainer').removeClass('d-none');
                    $('#circumstanceOtherText').prop('disabled', false);
                } else {
                    $('#circumstanceTextContainer').addClass('d-none');
                    $('#circumstanceOtherText').prop('disabled', true);
                }
            });
        }
    }

    function setCaiListener() {
        let negativeCai = document.getElementById('negative-cai');
        let positiveCai = document.getElementById('positive-cai');
        if(negativeCai !== undefined && negativeCai !== null){
            negativeCai.onclick = function(){
                $('#cai-additional-info').removeClass('d-none');
                $('#circumstanceType').prop('disabled', false);
                $('#circumstanceInvolvedVehicles').prop('disabled', false);
                $('#positiveComplaint').prop('disabled', false);
                $('#negativeComplaint').prop('disabled', false);
                $('#positiveInjured').prop('disabled', false);
                $('#negativeInjured').prop('disabled', false);
            }
        }
        if(positiveCai !== undefined){
            positiveCai.onclick = function(){
                $('#cai-additional-info').addClass('d-none');
                $('#circumstanceType').prop('disabled', true);
                $('#circumstanceInvolvedVehicles').prop('disabled', true);
                $('#positiveComplaint').prop('disabled', true);
                $('#negativeComplaint').prop('disabled', true);
                $('#positiveInjured').prop('disabled', true);
                $('#negativeInjured').prop('disabled', true);
            }
        }
    }

    function validateForm(form){
        var errors = [];
        var formFieldsTypes = ['select.form-control','textarea.form-control','input.form-control[type=text]',
            'input.form-control[type=number]','input.form-control[type=email]', 'input.form-control[type=date]'];


        formFieldsTypes.forEach((formField) => {
            form.find(formField).each(function () {
                var currentElement = $(this);
                if (currentElement.data('validate-required')===true){
                    var value = currentElement.val();
                    if(value===''){
                        errors.push(currentElement.attr('name'));
                    }else if (currentElement.attr('type')==='email' && !isValidEmail(value)){
                        errors.push(currentElement.attr('name'));
                    }
                }
            });
        })

        return errors;
    }

    function showErrors(form, errors, message){
        var feedbackMessage = form.find('p.feedback-message');
        if (feedbackMessage.length > 0) {
            feedbackMessage.remove()
        }
        if (message===''){
            message = form.find('input#error_message[type=hidden]').val();
        }
        form.append('<p class="mt-2 err feedback-message">' + message + '</p>');

        if(errors.length>0){
            errors.forEach((element) => {
                form.find('#'+element+'-field').addClass('is-invalid');
            });
        }

        var header = $(".nav-main");
        var nav = $(".nav-top");
        var extraHeight = 0;
        if (header.length && nav.length) {
            extraHeight = header.outerHeight()+nav.outerHeight();
        }
        $('html, body').animate({
            scrollTop: form.offset().top-extraHeight
        }, 1500);

        if (form.find('button.g-recaptcha').length>0){
            grecaptcha.reset();
        }
    }

    function manageResponse(json, form) {
        if (json.success) {
            if (form.data('thank-you-page-type') === 'in-page'){
                form.parents("section").addClass("d-none");

                $('#'+form.attr('id')+'-thankYou-section').removeClass('d-none');
            }else if (form.data('thank-you-page-type') === 'redirect'){
                window.location.replace(form.data('thank-you-page'));
            }
        } else {
            var errors = json.errors;
            if (typeof errors !== 'undefined'){
                errors = Object.keys(errors);
            }else {
                errors = [];
            }
            showErrors(form, errors, json.message)
        }
        grecaptcha.reset();
    }

    return {
        init: init,
    };
})(jQuery);