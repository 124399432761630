var stepByStep = (function () {
    var form = $("#car-detail-quote-request form");
    var $btnSubmit = $('.form-preventivo button.main-submit-btn');
    var hasCarDetails = true;
    function init() {
        $(document).ready(function () {
            managePMEform();
            manageCustomerType();
            expandCompany();
            if (form.length){
                setCampaignFromLocalStorage();
            }
            $btnSubmit.on("click", function (e) {
                var isValid = formIsValid();
                if(!isValid) {
                    e.preventDefault();
                    scrollToTopForm();
                } else{
                    if($(".form-preventivo #recaptcha").length > 0) {
                        grecaptcha.execute();
                    }
                }

                /*if (isValid && !isLast) {
                    goNextStep();
                }*/
            });
            showErrors();
            helper();
        });
    }

    function setCampaignFromLocalStorage(){
        var utm_params = utmParametersManager.getUtmParametersFromStorage();
        var campaignContent = $('[name="campaignContent"]');
        var campaignMedium = $('[name="campaignMedium"]');
        var campaignSource = $('[name="campaignSource"]');
        var campaignName = $('[name="campaignName"]');
        var campaignTermGoogleAnalytics = $('[name="campaignTermGoogleAnalytics"]');
        if (utm_params !== null){
            if (campaignContent.length){
                campaignContent.val(utm_params.utm_content)
            }
            if (campaignMedium.length){
                campaignMedium.val(utm_params.utm_medium)
            }
            if (campaignSource.length){
                campaignSource.val(utm_params.utm_source)
            }
            if (campaignName.length){
                campaignName.val(utm_params.utm_campaign)
            }
            if (campaignTermGoogleAnalytics.length){
                campaignTermGoogleAnalytics.val(utm_params.utm_term)
            }
        }
    }

    function scrollToTopForm() {
        var header = $(".nav-main");
        var nav = $(".nav-top");
        var offerSticky = $(".offer-sticky");
        var extraHeight = 0;
        if (header.length && nav.length && offerSticky.length) {
            extraHeight = header.outerHeight()+nav.outerHeight()+offerSticky.outerHeight();
        }
        $('html, body').animate({
            scrollTop: form.offset().top-extraHeight
        }, 1500);
    }

    function managePMEform() {
        $(".pme [name=fleet_elements]").on("change", function () {
            var fieldToDisable = $("[name=offer_model], [name=offer_months], [name=offer_km]");
            if ($(this).val() === '0-3') {
                hasCarDetails = true;
                fieldToDisable.prop("disabled", false)
                    .removeClass('disabled');
                $.each(fieldToDisable, function () {
                    $(this).attr("data-validate-required", true)
                });
                $(this).closest('.main-quote-request-form').removeClass('no-car-details')
            } else {
                hasCarDetails = false;
                fieldToDisable.prop("disabled", true)
                    .addClass('disabled');
                $.each(fieldToDisable, function () {
                    $(this).attr("data-validate-required", false)
                });
                $(this).closest('.main-quote-request-form').addClass('no-car-details')
            }
        });
    }

    function manageCustomerType() {
        if ($('[name=customer_type]').val() == 'private'){
            $("[name=fleet_elements]").closest('.col-sm-6').addClass('d-none');
            $("[name=fleet_elements]").closest('.col-12').addClass('d-none');
            $("[name=fleet_elements]").attr("data-validate-required", false);
        }
        if ($('[name=customer_type]').val() == 'professional' || $('[name=customer_type]').val() == 'corporate'){
            expandCompany();
        }
        $("select[name=customer_type]").on("change", function () {
            var fieldToDisable = $("[name=company], [name=fleet_elements], [name=company_registration_number]");
            var varNumber = $('[name=vat_number]').closest('.col-sm-6');
            if ($(this).val() === 'private') {
                $('[name=phone]').closest('.row').append(varNumber)
                fieldToDisable.closest('.col-sm-6').addClass('d-none')
                if(fieldToDisable.parent().hasClass('col-12') || fieldToDisable.parent().hasClass('col-sm-12')){
                    fieldToDisable.parent().addClass('d-none');
                }
                fieldToDisable.prop("disabled", true)
                    .addClass('disabled');
                $.each(fieldToDisable, function () {
                    $(this).attr("data-validate-required", false)
                });
                showAlternativeLabel(true);
            } else {
                expandCompany();
                $('[name=fleet_elements]').attr('data-validate-required')
                $('[name=company]').closest('.row').append(varNumber)
                fieldToDisable.closest('.col-sm-6').removeClass('d-none')
                if(fieldToDisable.parent().hasClass('col-12') || fieldToDisable.parent().hasClass('col-sm-12')){
                    fieldToDisable.parent().removeClass('d-none');
                }
                fieldToDisable.prop("disabled", false)
                    .removeClass('disabled');
                $.each(fieldToDisable, function () {
                    $(this).attr("data-validate-required", true)
                });
                showAlternativeLabel(false);
            }
        });
    }

    function expandCompany(){
        var company = $('[name=company]');
        if (company.closest('.row').find('div').length === 1){
            company.parent().addClass('col-sm-12');
        }
    }

    function showAlternativeLabel(showAlternative){

        var vatNumber = $('input[id="vat_number-field"]');
        var vatNumberLabel = $('label.not-alternative-label');
        var vatNumberLabelAlternative = $('label.alternative-label');

        if(showAlternative){
        vatNumberLabel.addClass('d-none');
        vatNumberLabelAlternative.removeClass('d-none');
        vatNumber.attr("placeholder", vatNumberLabelAlternative.text())
        } else {
        vatNumberLabel.removeClass('d-none');
        vatNumberLabelAlternative.addClass('d-none');
        vatNumber.attr("placeholder",vatNumberLabel.text());
        }
    }



    function showErrors() {
        if ($('.err', form).length) {
            var collapseContainer = $('.offer-page #car-detail-quote-request-collapse-container');
            if (collapseContainer.length) {
                collapseContainer.collapse("show");
                scrollToElementManage.scrollTo(collapseContainer);
            }
            //mainFormCarousel.trigger('to.owl.carousel', $('.err', form).closest('.item.step').attr('data-step'));
        }
    }


    function showHideMainSubmitBtn() {

    }



    function formIsValid() {
        var isValid = true;
        $("input.form-control, select.form-control", form).each(function () {
            regexPattern = $(this).attr("pattern");
            if($(this).attr("data-validate-required") === "false" && !regexPattern) return;
            if(regexPattern){
                re = new RegExp($(this).attr("pattern"));
            }


            if (regexPattern) {
                var currentIsValid = (
                    ($(this).attr("type") === "email" && isValidEmail($(this).val())) ||
                    ($(this).attr("type") === "text" && $(this).val() !== '' && regexPattern && re.test($(this).val())) ||
                    ($(this).attr("type") === "number" && $(this).val() !== '') ||
                    ($(this).is("select") && $(this).val() !== '') ||
                    ($(this).attr("type") === "radio" && $(this).attr("data-validate-present") === 'true' && $(this).parent().find("input:radio[name='"+ this.name +"']").is(":checked")) ||
                    ($(this).attr("type") === "radio" && $(this).is(":checked"))
                );
            } else {
                var currentIsValid = (
                    ($(this).attr("type") === "email" && isValidEmail($(this).val())) ||
                    ($(this).attr("type") === "text" && $(this).val() !== '') ||
                    ($(this).attr("type") === "number" && $(this).val() !== '') ||
                    ($(this).is("select") && $(this).val() !== '') ||
                    ($(this).attr("type") === "radio" && $(this).attr("data-validate-present") === 'true' && $(this).parent().find("input:radio[name='"+ this.name +"']").is(":checked")) ||
                    ($(this).attr("type") === "radio" && $(this).is(":checked"))
                );
            }

            isValid = isValid && currentIsValid;
            if(!currentIsValid) {
                if($(this).attr("type") === "radio") {
                    $(this).closest(".radio-container").addClass("is-invalid")
                } else {
                    $(this).addClass('is-invalid');
                }
            } else {
                if($(this).attr("type") === "radio") {
                    $(this).closest(".radio-container").removeClass("is-invalid")
                } else {
                    $(this).removeClass('is-invalid');
                }
            }
        });
        if ($(".textareaLike").length) {
            isValid = isValid && true;
        }
        // if ($(".form-check-input", currentStep).length) {
        //     if ($(".form-check-input", currentStep).is(':checked') && $(".form-check-input:checked", currentStep).length == 2) {
        //         isValid = true;
        //         $(".form-check-input", currentStep).removeClass('is-invalid');
        //     } else {
        //         isValid = false;
        //         $(".form-check-input", currentStep).addClass('is-invalid');
        //     }
        // }

        return isValid;
    }

    function helper() {
        $('#infoModal').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);
            var title = button.data('title');
            var description = button.data('description');
            var modal = $(this);
            modal.find('.modal-title').text(title);
            modal.find('.modal-body p').html(description);
        })
    }

    function initializeForm(){
        if($('.main-quote-request-form select[name="customer_type"]').select2().length > 0){
            if($('.main-quote-request-form select[name="customer_type"]').select2().val() === ''){
                $('.main-quote-request-form select[name="customer_type"]').select2().val('professional');
            }
            $('.main-quote-request-form select[name="customer_type"]').select2().trigger('change');
        }
    }

    return {
        init: init,
        initializeForm: initializeForm
    };
})(jQuery);
