var offerDetailManager = (function() {
    function init() {
        var offerPage =  $(".offer-page");
        if(offerPage.length === 0) return;
        var isLanding =  $(".offer-page .convenzioni").length;
        var navigationHeader = $(".nav-main");
        var offerSticky = offerPage.find('.offer-sticky');
        
        topBannerImageContainer = offerPage.find(".offer-box-compact-container");
        //BANNER IMAGES
        desktopBannerImage = offerPage.find(".offer-box-compact-container").data("desktop-banner");
        tabletBannerImage = offerPage.find(".offer-box-compact-container").data("tablet-banner");
        mobileBannerImage = offerPage.find(".offer-box-compact-container").data("mobile-banner");

        if (offerSticky.length){
            offerSticky.find("a.btn[href^='#car-detail-quote-request'").on('click', function () {
                scrollToQuoteRequest(navigationHeader.height()+offerSticky.height());
            })
        }

        $(document).ready(function () {
            handleBackgroundImages();
        })
        $(window).on("resize", function () {
            handleBackgroundImages();
        })

        offerPage.find('.offer-box-compact-container .offer-box-compact-container-left .offer-card.offer-card-compact').on('click', function (e) {
            const classesToCheck = ['offer_annotations_popover_link', 'info-icon-blue', 'favourite-icon-blue', 'favourite'];
            var targetClasses = e.target.classList;
            if (typeof targetClasses != "undefined" && targetClasses.length){
                var scrollTo = true;
                $.each(classesToCheck, function (index, element) {
                    if (targetClasses.contains(classesToCheck[index])){
                        scrollTo = false;
                        return false;
                    }
                });
                if (scrollTo){
                    offerSticky.removeClass('d-none');
                    const heightToRemove = offerSticky.height()+navigationHeader.height();
                    offerSticky.addClass('d-none');
                    scrollToQuoteRequest(heightToRemove);
                }
            }

        })
        var quoteRequest = offerPage.find('#car-detail-quote-request-collapse-container');
        var topCtaContainer =offerPage.find('.top-ctas');
        if (topCtaContainer.length){
            topCtaContainer.find("a.btn[href^='#car-detail-quote-request'").on('click', function () {
                scrollToQuoteRequest(navigationHeader.height()+offerSticky.height());
            });

            if ($(window).width()>576) {
                var maxWidthCta = 0;
                var topCtas = topCtaContainer.find('a.btn');
                topCtas.each( function(){
                    if ($(this).width() > maxWidthCta) {
                        maxWidthCta = $(this).width()
                    }
                });
                topCtas.width(maxWidthCta);
            }
        }

        $(window).on('scroll', function() {
            if ($(this).scrollTop() > $(".new-slider-component", offerPage).outerHeight()) {
                navigationHeader.removeClass('shadow-lg')
                if (!isLanding){
                    offerSticky.removeClass('d-none');
                }
            } else {
                navigationHeader.addClass('shadow-lg')
                if (!isLanding){
                    offerSticky.addClass('d-none');
                }
            }
        });
        $(offerPage).find("a[href^='#car-detail-quote-request']").each(
            function() {
                $(this).bind("click", function() {
                    $('#car-detail-quote-request-collapse-container', offerPage).collapse("show")
                })
            }
        )
        $('#car-detail-quote-request-collapse-container', offerPage).on('show.bs.collapse', function (){
            var varNumber = $('[name=vat_number]').closest('.col-sm-6');
            if ($("select[name=customer_type]").val() === 'private') {
                $('[name=phone]').closest('.row').append(varNumber);
            } else {
                $('[name=company]').closest('.row').append(varNumber)
            }
        })

        function scrollToQuoteRequest(heightToRemove) {
            if (typeof heightToRemove != "undefined"){
                $('html, body').stop().animate({
                    scrollTop: quoteRequest.offset().top - heightToRemove
                }, 1500);
            }
        }
    }

    function handleBackgroundImages() {
        if($(window).width() >= 992){
            setTopBannerBackgroundImage(desktopBannerImage);
        }
        if($(window).width() < 992 && $(window).width() >= 576){
            setTopBannerBackgroundImage(tabletBannerImage);
        }
        if($(window).width() < 576){
            setTopBannerBackgroundImage(mobileBannerImage);
        }
    }

    function setTopBannerBackgroundImage(imageUrl){
        topBannerImageContainer.css("background-image", "url(" + imageUrl + ")")
    }

    return {
        init: init
    };
})(jQuery);