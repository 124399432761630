var offersManager = (function($){

    var $DOM = {};
    var isLastPage = true;
    var currentPage = 0;
    var isStart;
    var isFilter = false;

    function init() {

        $DOM.wrapper = $(".offerte-listing");
        $DOM.filters = $(".offerte-filters");

        $DOM.filtersForm = $(".offerte-filters").find("form");
        if(!$DOM.wrapper.length || !$DOM.filtersForm.length) {
            return;
        }
        $DOM.container = $DOM.wrapper.find("main");
        $DOM.offerPlace = $DOM.container.find(".offers-place");
        $DOM.loadMoreBtn = $DOM.wrapper.find("a.load-more");
        $DOM.loading = $("<div class='loading d-none'></div>");
        $DOM.loadMoreBtn.after($DOM.loading);

        isLastPage = $DOM.container.data("is-last-page");

        isStart = true;
        loadMore();

        renderLoadMoreBtn();

        handleUI();

        highlightOfferCarousel();

        eventHandler()

        window.onscroll = function () {
            if($('.offer-card').length === 0){
                return;
            }
            if($("a.load-more").hasClass("d-none")){
                return;
            }
            var hT = $("a.load-more").not(".d-none").offset().top;
            var hH = $("a.load-more").outerHeight();
            var wH = $(window).height();
            var wS = $(this).scrollTop();
        
            if (wS > (hT+hH-wH)){
                loadMore();
            }
          };

    }

    function eventHandler() {
        $(".form-filter").on("submit", function (e) {
            isFilter = true;
            isLastPage = false;
            currentPage = 0;
            loadMore();
            e.preventDefault();
        });
    }

    function highlightOfferCarousel() {
        var offerHighlight = $('.offer-highlight .owl-carousel');
        offerHighlight.owlCarousel({
            dots: false,
            nav: true,
            navText: [
                "<i class='prev-icon'></i>",
                "<i class='next-icon'></i>"
            ],
            mouseDrag:false,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 15,
                    singleItem: true,
                    nav: false,
                    dots: false,
                    center: true,
                    margin: -55,
                    loop: true,
                },
                767: {
                    items: 1,
                },
                1200:{
                    items: 1
                }
            },
            onInitialized: callback,
            onResized: callback
        });
        function callback(events) {
            applyHeightToFlipBox();
        }
    }

    function applyHeightToFlipBox() {
        $(".offer-highlight .owl-carousel .flip-card").each(function () {
            var heightToApply = $(this).find('.offer-box-front');
            var backFlip = $(this).find('.offer-box-back');
            $(this).css({
                'height': heightToApply.height()
            });
            backFlip.css({
                'height': heightToApply.height()
            })
        });
    }

    function renderLoadMoreBtn() {
        if(isLastPage) {
            $DOM.loadMoreBtn.remove();
        }
        else {

            $DOM.loadMoreBtn.show();
        }
    }

    function handleUI() {
        $DOM.loadMoreBtn.on("click",function(e){
            e.preventDefault();
            loadMore();
        });
    }

    function getFilters() {
        var filters = {};
        //var filtersArray = $DOM.filtersForm.serializeArray();
        var filtersArray = $("#hidden-filter-form").serializeArray();
        $.each(filtersArray, function(k,item) {
            filters[item.name] = item.value;
        });
        return filters;
    }

    function hideBtn() {
        $DOM.loadMoreBtn.addClass('d-none');
    }
    function showBtn() {
        $DOM.loadMoreBtn.removeClass('d-none');
    }
    function showLoading() {
        $DOM.loading.removeClass("d-none");
    }
    function hideLoading() {
        $DOM.loading.addClass('d-none');
    }

    function heightBoxCard() {
        var flipCard = $('.flip-card');
        flipCard.each(function() {
            var flipCard = $(this);
            var flipCardInner = $(this).find('.flip-card-inner').innerHeight();
            var heightToApplyBtn = $(this).find('.btn-leasys-red').innerHeight();
            var heightToApplyInfos = $(this).find('.flip-card-more-info').innerHeight();
            var summed = flipCardInner+heightToApplyInfos;
            //flipCard.css('height', flipCardInner+heightToApplyBtn+heightToApplyInfos);
            flipCard.find('.flip-card-front').css('position', 'relative');
            flipCard.css('height', '100%');
            flipCard.css('max-height', summed);
        });
        flipCardResize();
          $(window).resize(flipCardResize);
          function flipCardResize() {
            var maxHeight = 0;
            flipCard.each(function() {
                var flipCardInner = $(this).find('.flip-card-inner').innerHeight();
                var flipCardInnerParent = $(this).find('.flip-card-inner').parent().parent().innerHeight();
                var heightToApplyInfos = $(this).find('.flip-card-more-info').innerHeight();
                var summed = flipCardInner+heightToApplyInfos;
                ////console.log("DATAS In "+flipCardInner+" - Out "+flipCardInnerParent+" - Sum "+summed+" - Max "+maxHeight);
                ////if(flipCardInner > maxHeight) maxHeight = flipCardInnerParent;
                if(flipCardInnerParent > summed){
                    maxHeight = flipCardInnerParent;
                } else {
                    maxHeight = summed;
                }
                ////console.log("DATAS Max After "+maxHeight);
            });
            flipCard.each(function() {
                var flipCard = $(this);
                flipCard.find('.flip-card-front').css('position', 'relative');
                //flipCard.find('.flip-card-back').css('position', 'relative');
                flipCard.css('height', '100%');
                flipCard.css('max-height', maxHeight);
            });
          }
    }

    function fullCardResize() {
        var classToCheck = ['.offer-price-details', '.car-properties', '.offer-title', '.offer-subtitle', '.products-banner, .offer-card-cta-container', '.first-bottom-text', '.second-bottom-text'];
        var offersCards = $('.offerte-listing .offer-card');

        classToCheck.forEach((className) => {
            offersCards.find(className).css('height', 'auto');
        })

        if($(window).width() > 767) {
            var offerCardRows = $('.offerte-listing .offer-card:odd').length;
            for (let row = 0; row < offerCardRows; row++) {

                var firstElementIndex = row * 2;
                var secondElementIndex = (row * 2) + 1;
                var firstElement = offersCards.eq(firstElementIndex);
                var secondElement = offersCards.eq(secondElementIndex);

                classToCheck.forEach((className) => {
                    firstElement.find(className).css('height', 'auto');
                    secondElement.find(className).css('height', 'auto');
                    var firstElemSections = firstElement.find(className);
                    var secondElemSections = secondElement.find(className);
                    var firstElemHeight = 0;
                    var secondElemHeight = 0;
                    if (firstElemSections.length>1){
                        firstElemSections.each(function () {
                            firstElemHeight = ($(this).innerHeight()>firstElemHeight)? $(this).innerHeight() : firstElemHeight;
                        })
                    }else{
                        firstElemHeight = firstElemSections.innerHeight();
                    }
                    if (secondElemSections.length>1){
                        secondElemSections.each(function () {
                            secondElemHeight = ($(this).innerHeight()>secondElemHeight)? $(this).innerHeight() : secondElemHeight;
                        })
                    }else{
                        secondElemHeight = secondElemSections.innerHeight();
                    }

                    var maxHeight = Math.max(firstElemHeight, secondElemHeight);
                    firstElement.find(className).css('height', maxHeight);
                    secondElement.find(className).css('height', maxHeight);
                })
            }
        }
    }

    $(window).resize(onWindowResize);

    function onWindowResize() {
        offerPricePlacement();
        fullCardResize();
    }

    function offerPricePlacement() {
        var offerCard = $('.offer-card');
        if(offerCard.width() <= 365){
            offerCard.find('.offer-price-placement').css('margin-top', '-10px');
            offerCard.find('.starting-from').css('margin-top', '15px');
            offerCard.find('.stock-bubble').css('margin-top', '1.5rem');
        } else {
            offerCard.find('.offer-price-placement').css('margin-top', '-35px');
            offerCard.find('.stock-bubble').css('margin-top', '3rem');
            offerCard.find('.starting-from').css('margin-top', '0');
        }
    }


    /*function fullCardResize() {
        //var classesToResize = $('.offer-price-details, .car-properties, .offer-title, .offer-subtitle, .product-area, .bottom-text');
        var offerCard = $('.offer-card');
        var maxHeightOfferPriceDetails = 0;
        var maxHeightCarProperties = 0;
        var maxOfferTitle = 0;
        var maxOfferSubtitle = 0;
        var maxOfferProductArea = 0;
        var maxBottomText = 0;
        offerCard.each(function() {
            var offerPriceDetails = $(this).find('.offer-price-details').innerHeight();
            var offerCarProperties = $(this).find('.car-properties').innerHeight();
            var offerTitle = $(this).find('.offer-title').innerHeight();
            var offerSubtitle = $(this).find('.offer-subtitle').innerHeight();
            var offerProductArea = $(this).find('.product-area').innerHeight();
            var bottomText = $(this).find('.bottom-text').innerHeight();
            if(offerPriceDetails > maxHeightOfferPriceDetails){
                maxHeightOfferPriceDetails = offerPriceDetails;
            }
            if(offerCarProperties > maxHeightCarProperties){
                maxHeightCarProperties = offerCarProperties;
            }
            if(offerTitle > maxOfferTitle){
                maxOfferTitle = offerTitle;
            }
            if(offerSubtitle > maxOfferSubtitle){
                maxOfferSubtitle = offerSubtitle;
            }
            if(offerProductArea > maxOfferProductArea){
                maxOfferProductArea = offerProductArea;
            }
            if(bottomText > maxBottomText){
                maxBottomText = bottomText;
            }
        });
        offerCard.each(function() {
            $(this).find('.offer-price-details').css('height', maxHeightOfferPriceDetails);
            $(this).find('.car-properties').css('height', maxHeightCarProperties);
            $(this).find('.offer-title').css('height', maxOfferTitle);
            $(this).find('.offer-subtitle').css('height', maxOfferSubtitle);
            $(this).find('.product-area').css('height', maxOfferProductArea);
            $(this).find('.bottom-text').css('height', maxBottomText);
        });
    }

    $(window).resize(onWindowResize);

    function onWindowResize(){
        var offerCard = $('.offer-card');
        offerCard.each(function() {
            $(this).find('.offer-price-details').css('height', 'auto');
            $(this).find('.car-properties').css('height', 'auto');
            $(this).find('.offer-title').css('height', 'auto');
            $(this).find('.offer-subtitle').css('height', 'auto');
            $(this).find('.product-area').css('height', 'auto');
            $(this).find('.bottom-text').css('height', 'auto');
        });
        fullCardResize();
    }*/


    function loadMore() {

        var url = $DOM.container.data("load-more-url");
    
        hideBtn();
        showLoading();
    
        var filters = getFilters();
        updateUrlWithFilter(filters);
        var favouritesFromStorage =
          JSON.parse(localStorage.getItem(`${ENV.market}.favorites`)) || [];
        var favourites = "";
        if (ENV.isFavouritePage !== "" && ENV.isFavouritePage === "1") {
          // 0 serve per ritornare 0 objects nel caso in cui non ci siano preferiti e siamo comunque sulla pagina dei preferiti
          favourites = favouritesFromStorage.length
            ? favouritesFromStorage.join("|")
            : 0;
        }
        var mainFilters = {
          ...filters,
          return: "json",
          page: currentPage,
          favourites: favourites,
        };
        
        if (!isLastPage) {
          var data = {
            filters: mainFilters,
          };
    
          $.ajax({
            method: "GET",
            url: url,
            data: data.filters,
            dataType: "json",
          }).done(function (ret) {
            window.setTimeout(function () {
              hideLoading();
              if (ret.success) {
                isLastPage = ret.data.isLastPage;
                currentPage += 1;
                $(".results-counter").empty().text(ret.data.totalItems+' '+ret.data.resultLabelDesktop);
                $('.btn-filter-collapse-apply').empty().text(ret.data.totalItems+' '+ret.data.resultLabelResponsive);
                showContractOffersWhenTargetSelected(data.filters);
                renderItems(ret.data.items, filters.perRow, ret.data.totalItems, ret.data.relatedItems);
                offerPricePlacement();
                heightBoxCard();
                fullCardResize();
                if (!isLastPage) {
                  showBtn();
                }
                offerAnnotationsPopoverManager.init();
                  setTimeout(() => {
                      relativeOffersManager.init();
                  }, 0);

                  $('.offer-card.offer-new, .offer-card.offer-card-compact').click(function(e){
                      e.preventDefault();
                      if($(e.target).data('link-url')){
                          window.open($(e.target).data('link-url'), '_blank');
                      } else if($(e.target).data("annotation-link")){
                        return;
                    } else{
                          window.location.href=$(e.target).closest('.offer-card.offer-new, .offer-card.offer-card-compact').data('link-url');
                      }
                  });

              } else {
                isLastPage = true;
              }
            }, 10);
          });
        }
      }

    function updateUrlWithFilter(filters) {
        var url = new URL(window.location.href);

        Object.keys(filters).forEach(key => {
            url.searchParams.set(key, filters[key]);
        })
        
        history.replaceState(null, null, url); 
        //history.pushState(null, document.title, window.location.pathname + '?'+$.param(filters));
    }

    function showContractOffersWhenTargetSelected(filters) {
        $('.isLandingPage').removeClass('d-none');
        return;
        if(filters.target_client){
            $('.isLandingPage').removeClass('d-none');
        } else {
            $('.isLandingPage').addClass('d-none');
        }
    }

    function renderItems(items, perRow, totalItems, relatedItems) {

        var template;

        if (perRow === "1") {
            template = TPL.offerfull;
        } else if(perRow === "2") {
            template = TPL.offerte_restyle_compact;
        }

        if (isFilter) {
            $DOM.offerPlace.empty();
        }

        if(window.ENV.consumptionValuesMarkets.split('|').includes(window.ENV.market)) template = TPL.offerte_restyle_compact;
        if(totalItems>0) {
            switchRelatedOffers();
            hideNoResult();
            showOffersHeader();
            $.each(items, function(i,item){
                var tplData = {
                    item: item,
                    showCarPropertiesIcons: window.ENV.consumptionValuesMarkets.split('|').includes(window.ENV.market),
                    ENV: window.ENV
                };
                //CHECK IF DE and POWERTRAIN LABELS:
                if(window.ENV.consumptionValuesMarkets.split('|').includes(window.ENV.market) && item.powertrain){
                    let carPropertiesSnippet;

                    switch (item.powertrain) {
                        case "ice":
                            carPropertiesSnippet = TPL.consumption_ice
                            break;
                        case "bev":
                            carPropertiesSnippet = TPL.consumption_bev
                            break;
                    
                        default:
                            carPropertiesSnippet = TPL.consumption_phev
                            break;
                    }
                    tplData.carProperties = Mustache.render(carPropertiesSnippet, tplData);
                    var html = Mustache.render(TPL.offerte_restyle_compact_consumption, tplData);
                } else{
                    var html = Mustache.render(template, tplData);
                }
                
                $DOM.offerPlace.append(html);

            });

            if ($(window).width()>992){
                $('.filters-offers-mobile.filters-top-result').show();
            }
        } else {
            $('.filters-offers-mobile.filters-top-result .offers-sorting').hide();
            showNoResults();
            switchRelatedOffers(true, relatedItems);
            hideOffersHeader();
            showLoadMoreButton(false);
        }
        isFilter = false;
        if (!isStart) {
            window.setTimeout(function(){
                $("html,body").animate({
                    scrollTop: $DOM.loadMoreBtn.offset().top
                },750);
            },50);
            isStart = false;
        }

        refactorPrice.init();
        landingPageOfferClick();
        favouriteCarManager.updateFavouriteViewer();
    }

    function landingPageOfferClick() {
        if ($(".convenzioni").length){
            $(document).on("click", ".flip-card .btn.btn-leasys-red", function (){
                var titles = $(this).closest(".offer-box").find(".offer-box-back .offer-box-title");
                if (titles.length) {
                    var brand = titles[0] ? $(titles[0]).text() : '';
                    var model = titles[1] ? $(titles[1]).text() : '';
                    var landingName = $(".convenzioni").val();
                    manageGTM.trackLandingPageOfferClick("Landing page - "+landingName,'Open offer Detail', brand+' '+model);
                }
            });
        }
    }

    function switchRelatedOffers(toShow, relatedItems){
        var relatedTemplate = TPL.related_offer;
        var listingRelatedOffers = $(".listing-related-offers");

        if(typeof toShow !== 'undefined' && toShow){
            if(relatedItems && relatedItems.length > 0) {
                $('.listing-related-offers .relative-offers .owl-carousel').empty();
                $.each(relatedItems, function (i, relatedItem) {
                    var rTplData = {
                        relatedItem: relatedItem,
                        showCarPropertiesIcons: window.ENV.consumptionValuesMarkets.split('|').includes(window.ENV.market),
                        ENV: window.ENV
                    };
                    var relatedHtml = Mustache.render(relatedTemplate, rTplData);
                    $('.listing-related-offers .relative-offers .owl-carousel').append(relatedHtml);

                });
            }
            listingRelatedOffers.addClass('d-block');
        } else {
            listingRelatedOffers.removeClass('d-block');
            listingRelatedOffers.addClass('d-none');
        }
        relativeOffersManager.setMaxHeightOffers();
    }

    function showNoResults() {
        $(".no-results", $DOM.container).removeClass('d-none');
    }

    function hideNoResult() {
        $(".no-results", $DOM.container).addClass('d-none');
    }

    function showOffersHeader() {
        $(".offerte-listing-header .offers-sorting").attr('style', 'display: flex');
    }
    function hideOffersHeader() {
        $(".offerte-listing-header .offers-sorting", $DOM.filters).attr('style', 'display: none');
    }

    function showLoadMoreButton(hasResults){
        if(!hasResults){
            $("a.load-more", $DOM.container).css('display','none');
        }
    }

    return {
        init: init,
        loadMore: loadMore
    };

})(jQuery);
