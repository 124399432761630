var formContattiConfigurabile = (function () {
    function init() {
        var formContatti = $('.form-contatti-configurabile');

        $('.form-contatti-configurabile select.select').select2({
            allowClear: true
        });

        formContatti.each(function () {

            var currentForm = $(this);
            var targetContainers = currentForm.find('.target_custom_data');
            var targetSelect = currentForm.find('select.target-field');

            if (targetSelect.length>0){
                var mainTarget = targetSelect.val().split('_')[0];
                if (mainTarget) {
                    $('.target_custom_data_' + mainTarget, $(this)).removeClass('d-none');
                }

                targetSelect.on('select2:select', function (e) {
                    var mainTarget = $(this).val().split('_')[0];
                    targetContainers.addClass('d-none');
                    targetContainers.find('input').val('');
                    targetContainers.find('select').val('').trigger('change');
                    currentForm.find('.target_custom_data.target_custom_data_' + mainTarget).removeClass('d-none');
                });
            }

            $(this).submit(function (e) {
                e.preventDefault();
                currentForm.find('.is-invalid').removeClass('is-invalid');
                var errors = validateForm(currentForm);
                if(errors.length===0){
                    $.ajax({
                        url: $(this).attr('action'),
                        type: $(this).attr('method'),
                        dataType: 'json',
                        data: $(this).serialize(),
                        success: function (json) {
                            manageResponse(json, currentForm);
                        }
                    });
                }else{
                    showErrors(currentForm, errors, '');
                }
            });


        });


    }

    function validateForm(form){
        var errors = [];
        var formFieldsTypes = ['select.form-control','textarea.form-control','input.form-control[type=text]',
            'input.form-control[type=number]','input.form-control[type=email]', 'input.form-control[type=date]'];
        var targetSelect = form.find('select.target-field');
        var selectedTarget = '';
        if (targetSelect.length>0){
            selectedTarget = targetSelect.val().split('_')[0];
        }else{
            selectedTarget = form.find('input.target-field').val().split('_')[0];
        }

        if (selectedTarget===''){
            errors.push('target');
        }else{
            formFieldsTypes.forEach((formField) => {
                form.find(formField).not('.target-field').each(function () {
                    var currentElement = $(this);
                    var elementRelatedTarget = currentElement.data('related-target').split('|');
                    if ( currentElement.data('validate-required')===true && (jQuery.inArray(selectedTarget,elementRelatedTarget)>-1 ||
                        (selectedTarget==='' && currentElement.parents('div.target_custom_data').length===0)) ){
                        var value = currentElement.val();
                        if(value===''){
                            errors.push(currentElement.attr('name'));
                        }else if (currentElement.attr('type')==='email' && !isValidEmail(value)){
                            errors.push(currentElement.attr('name'));
                        }
                    }
                });
            })

            var radioButtons = ['.form-fields-radio-container .radio-buttons', '.acceptance-box .radio-container'];
            radioButtons.forEach((singleButton) => {
                form.find(singleButton).each(function () {
                    var thisRadio = $(this).find('input[type=radio]');
                    if ( (thisRadio.data('validate-required')===true && !thisRadio.is(':checked')) ||
                        (thisRadio.data('validate-present')===true && !(thisRadio.first()).is(':checked')) ){
                        errors.push(thisRadio.attr('name'));
                    }
                });
            })
        }

        return errors;
    }

    function showErrors(form, errors, message){
        var feedbackMessage = form.find('p.feedback-message');
        if (feedbackMessage.length > 0) {
            feedbackMessage.remove()
        }
        if (message===''){
            message = form.find('input#error_message[type=hidden]').val();
        }
        form.append('<p class="mt-2 err feedback-message">' + message + '</p>');

        if(errors.length>0){
            errors.forEach((element) => {
                form.find('#'+element+'-field').addClass('is-invalid');
            });
        }

        var header = $(".nav-main");
        var nav = $(".nav-top");
        var extraHeight = 0;
        if (header.length && nav.length) {
            extraHeight = header.outerHeight()+nav.outerHeight();
        }
        $('html, body').animate({
            scrollTop: form.offset().top-extraHeight
        }, 1500);

        if (form.find('button.g-recaptcha').length>0){
            grecaptcha.reset();
        }
    }

    function manageResponse(json, form) {
        if (json.success) {
            if (form.data('thank-you-page-type') === 'in-page'){
                form.parents("section").addClass("d-none");

                $('#'+form.attr('id')+'-thankYou-section').removeClass('d-none');
            }else if (form.data('thank-you-page-type') === 'redirect'){
                window.location.replace(form.data('thank-you-page'));
            }
        } else {
            var errors = json.errors;
            if (typeof errors !== 'undefined'){
                errors = Object.keys(errors);
            }else {
                errors = [];
            }
            showErrors(form, errors, json.message)
        }
        grecaptcha.reset();
    }

    return {
        init: init,
    };
})(jQuery);