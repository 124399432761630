
function resetSingleSliderRange(slider){
    let localeSeparator = ENV.market == 'uk' ? 'en-UK' : 'it-IT';
    var min = slider.find( ".slider-min-sl-custom" );
    var min_start = parseInt(slider.find( ".slider-min" ).text());
    var max = slider.find( ".slider-max-sl-custom" );
    var max_start = parseInt(slider.find( ".slider-max" ).text());
    slider.find(".slide").slider({
        values: [ min_start, max_start ]
    });
    min.text( min_start.toLocaleString(localeSeparator) );
    max.text( max_start.toLocaleString(localeSeparator) );
}

$(function () {

    navbarManager.init();
    sideMenuManager.init();
    ourProductsCarouselManager.init();
    offersCarouselManager.init();
    sliderManager.init();
    newSliderCarouselManager.init();
    boxCarouselManager.init();
    leasysWizardManager.init();
    leasysMapCarousel.init();
    revealOfferBackManager.init();
    newsImageCarouselManager.init();
    offersFilterManager.init();
    offersManager.init();
    newOffersManager.init();
    leasysInNumber.init();
    serviceIncludeManager.init();
    relativeOffersManager.init();
    formPreventivo.init();
    formContatti.init();
    popoverOfferPackage.init();
    optionalIncludedManager.init();
    businessLinesManager.init();
    videoPlayOnHoverManager.init();
    scrollToElementManage.init();
    ourStoryCarouselManager.init();
    formManager.init();
    shakeit.init();
    troubleTicketRequest.init();
    ugo.init();
    normativaAccordion.init();
    howItWorkCarousel.init();
    stepByStep.init();
    contactsBoxCarouselManager.init();
    featureIncludedManager.init();
    governanceCarouselManager.init();
    ieDetector.init();
    offerDetailManager.init();
    //offerteFilters.init();
    gamificationManager.init();
    leasysInEuropeManager.init();
    leasysWorldCarouselManager.init();
    searchModalManager.init();
    icarePackagesTabs.init();
    orgChartManager.init();
    advantagesCarouselManager.init();
    regionManager.init();
    boxPillsManager.init();
    tabAreasCard.init();
    stockSearchManager.init();
    favouriteCarManager.init();
    additionalPackageManager.init();
    customizeOfferManager.init();
    carGalleryManager.init();
    utmParametersManager.init();
    assistanceBlockR23.init();
    halfBoxTabsR23.init();
    blockBasicProR23.init();
    roundedBoxesSliderManager.init();
    verticalImageTextSliderManager.init();
    //blogManager.init();
    relatedBlogsManager.init();
    offerAnnotationsPopoverManager.init();
    imageTextListCardsCarouselManager.init();
    iconTextListCardsCarouselManager.init();
    leftRightImageTextManager.init();
    formContattiConfigurabile.init();
    campaignLandingManager.init();
    campaignOffersCarouselManager.init();
    servicesRestyledManager.init();
    newTabAreasManager.init();
    chartsManager.init();
    peopleManager.init();
    pressAreaManager.init();
    iconsTabAreasManager.init();
    mediaAreaManager.init();
    imagePinsManager.init();
    objectListingManager.init();
    genericTextCarouselManager.init();
    careersListingManager.init();
    careersFormManager.init();
    careersDetailsManager.init();
    topHeaderManager.init();
    faqManager.init();
    newSliderSmallManager.init();
    timelineManager.init();
    newSliderSmallGamification.init();
    claimRequestForm.init();
    footerManager.init();
    contactPageTrackManager.init();
});

window.onload = function(){
    revealOfferBackManager.init(true);
    stepByStep.initializeForm();
    generateCsrfToken.init();
    formPreventivo.initMultipleOffers();
}
