var faqManager = function () {
    var faqCategoriesListing = $('.faq .icons-tab-areas.categories-listing');
    var faqQuestionsListing = $('.faq .icons-tab-areas.questions-listing');
    var tabContents = $('.faq .icons-tab-areas .tab-content');
    function init() {

        $(document).ready(function () {
            if (faqQuestionsListing.length>0 && $('.collapse.show.scrollToQuestion', faqQuestionsListing)) {
                var heightToRemove = $(".nav-main").outerHeight() + $('.nav-top').outerHeight() + $('breadcrumb-container').outerHeight();
                var questionToScroll = $('.collapse.show.scrollToQuestion', faqQuestionsListing).closest('.accordion-single-row');
                $('html, body').stop().animate({
                    scrollTop: (questionToScroll.offset().top - heightToRemove)
                }, 1500);
            }
        });

        $(window).on('shown.bs.tab', function () {
            if (faqCategoriesListing.length>0){
                $('.nav-link', faqCategoriesListing).each(function () {
                    var buttonIcon = $(this).find('.button-icon');
                    if ($(this).hasClass('active')){
                        buttonIcon.attr('src', buttonIcon.data('active-icon'));
                    }else{
                        buttonIcon.attr('src', buttonIcon.data('inactive-icon'));
                    }
                });
            }
        });


    }

    return {init:init};
}(jQuery);